import React from "react"
import { Navigate } from "react-router-dom"
import { useAuthentication } from "./common/services/authentication.hook"

interface ProtectedRouteProps {
    element: JSX.Element
    redirectTo: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, redirectTo }) => {
    const auth = useAuthentication()
    return auth.user ? element : <Navigate to={redirectTo} />
}

export default ProtectedRoute
