import React from "react"
import { useTranslation } from "react-i18next"
import { useAuthentication } from "../../common/services/authentication.hook"
import ArrowDecoration from "../../common/components/decorations/element-decoration/ArrowDecoration"
import cloud404 from "../../common/assets/404 Cloud.svg"
import "./404.scss"

export const PageNotFoundComponent = () => {
    const auth = useAuthentication()
    const { t } = useTranslation()

    return (
        <div className={"s404__container"}>
            <section className={"s404"} data-testid={"s404"}>
                <div className={"s404__content-wrapper"}>
                    <div className={"s404__content-wrapper__main"}>
                        <h1 className={"s404__content-wrapper__main__header"}>{t("PageNotFoundComponent.header")}</h1>
                        <div className={"s404__content-wrapper__main__text"}>{t("PageNotFoundComponent.text")}</div>
                    </div>
                    <div className={"s404__content-wrapper__links"}>
                        <div className={"s404__content-wrapper__links__home"}>
                        {t("PageNotFoundComponent.redirect-home")}{" "}
                            <a href="/" className={"s404__content-wrapper__links__home__link"}>
                            {t("PageNotFoundComponent.home")}
                            </a>
                        </div>
                        {auth.user && !auth.loading && (
                            <a href="/documents/" className={"s404__content-wrapper__links__insights"}>
                                {t("PageNotFoundComponent.redirect-documents")}
                                <ArrowDecoration className={"btn-arrow"} />
                            </a>
                        )}
                        {auth.user && (
                            <a href="/case-studies/" className={"s404__content-wrapper__links__services"}>
                                {t("PageNotFoundComponent.redirect-case-studies")}
                                <ArrowDecoration className={"btn-arrow"} />
                            </a>
                        )}

                        <a href="https://pcg.io/" target="__blank" className={"s404__content-wrapper__links__about"}>
                        {t("PageNotFoundComponent.redirect-pcg")}
                            <ArrowDecoration className={"btn-arrow"} />
                        </a>
                    </div>
                </div>
                <div className={"s404__image-wrapper"}>
                    <img className={"s404__image-wrapper__image"} src={cloud404}></img>
                </div>
            </section>
        </div>
    )
}
