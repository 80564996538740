import { useEffect } from "react"
import "./Navbar.scss"
import { useAuthentication } from "../../services/authentication.hook"
import PCG from "../../assets/img/logo-pcg/pcg-logo-horizontal.svg"

export const Navbar = (props: {}) => {
    const auth = useAuthentication()

    useEffect(() => {}, [])

    const menuItems: { name: String; navHref: string }[] = [
        ...(auth.user && !auth.loading
            ? [
                  {
                      name: "Case Studies",
                      navHref: "/case-studies",
                  },
                  {
                      name: "Documents",
                      navHref: "/documents",
                  },
              ]
            : []),
        ...(auth.isAdmin() && !auth.loading
            ? [
                  {
                      name: "Admin",
                      navHref: "/admin",
                  },
              ]
            : []),
    ]

    return (
        <div className="parent-wrapper">
            <div className="main-wrapper">
                <div className="navigation-container">
                    <nav className="nav-color navigation-bar">
                        <a href={"/"}>
                            <img className={"logo-pcg " + "logo-black"} src={PCG} alt={`PCG logo`} key={`PCG-logo`} />
                        </a>
                        <div className="categories">
                            {menuItems.map((item) => {
                                return (
                                    <a href={item.navHref} className={`main navbar-item primary-service`} style={{ color: "black" }}>
                                        {item.name}
                                    </a>
                                )
                            })}
                        </div>
                        <div className="user">
                            {auth.user ? (
                                <div className="user-container">
                                    <div className="user-image">
                                        {auth.user.attributes.given_name.charAt(0)}
                                        {auth.user.attributes.family_name.charAt(0)}
                                    </div>
                                    <div className="user-data">
                                        <div className="user-data-name">
                                            {auth.user.attributes.given_name} {auth.user.attributes.family_name}
                                        </div>
                                        <div className="user-data-email">{auth.user.username.replace("pcg-import-hub_", "")}</div>
                                    </div>
                                </div>
                            ) : (
                                <a href="/login">Log in</a>
                            )}
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
