import { useEffect, useState } from "react";
import { PCGUser } from "../../auth/models/PCGUser";
import {
  getUserAttributes,
  exchangeCodeForTokens,
  refreshTokens,
} from "./CognitoService";
export const useAuthentication = () => {
  const [user, setUser] = useState<PCGUser>(new PCGUser());
  const [loading, setLoading] = useState(true);

  const handleTokens = async (tokens: {
    access_token: string;
    id_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
  }) => {
    const { access_token, id_token, refresh_token, expires_in } = tokens;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_in", expires_in as unknown as string);
    //tes
    if (access_token) {
      const user = atob(access_token.split(".")[1]);
      const userObj = JSON.parse(user);
      userObj.exp = new Date(new Date().getTime() + 1000 * expires_in);
      const attributes = await getUserAttributes(access_token);
      userObj.attributes = attributes;
      localStorage.setItem("ih_sess", JSON.stringify(userObj));
      setUser(new PCGUser(userObj));
      setLoading(false);
      redirectToLogin();
    }
  };

  useEffect(() => {
    async function authUser() {
      if (window.location.href.includes("code=")) {
        const code = window.location.href.split("code=")[1];
        if (code)
          exchangeCodeForTokens(code)
            .then(async (result) => {
              await handleTokens(result);
            })
            .catch((err) => console.log(err));
      } else
        await handleAuthentication()
          .then((result) => {
            setUser(result as PCGUser);
            setLoading(false);
          })
          .catch((err) => console.log(err));
    }

    authUser();
  }, []);

  const handleAuthentication = async () => {
    const expires_in = localStorage.getItem("expires_in") as unknown as number;
    let session = localStorage.getItem("ih_sess");
    if (session) {
      const userObj = JSON.parse(session);
      if (new Date(userObj.iat * 1000 + 1000 * expires_in) < new Date()) {
        //try to refresh tokens
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken) {
          try {
            const newTokens = await refreshTokens(refreshToken);
            await handleTokens(newTokens);
          } catch (error) {
            localStorage.removeItem("ih_sess");
            redirectToLogin();
          }
        } else {
          localStorage.removeItem("ih_sess");
          redirectToLogin();
        }
      } else {
        const user = PCGUser.fromJson(session);
        return user;
      }
    }
  };

  const redirectToLogin = () => {
    window.location.href = "/";
  };

  const logout = () => {
    localStorage.removeItem("ih_sess");
    redirectToLogin();
  };

  const isAdmin = () => {
    return process.env.REACT_APP_ADMIN_SECURITY_GROUP && user
      ? user.attributes?.middle_name?.includes(
          process.env.REACT_APP_ADMIN_SECURITY_GROUP
        )
      : false;
  };

  const isAllPCGIntUsers = () => {
    return process.env.REACT_APP_ALL_PCG_INT_USERS && user
      ? user.attributes?.middle_name?.includes(
          process.env.REACT_APP_ALL_PCG_INT_USERS
        )
      : false;
  };

  return {
    user,
    loading,
    logout,
    isAdmin,
    isAllPCGIntUsers,
  };
};
